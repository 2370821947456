<template>
  <div class="pa-2">
    <v-form id=building >
      <div class="form-content">
        <div class="register">
          <div class="cd-info" v-if="room_code">{{room_code}}</div>
          <div class="flex">
            <v-select dense outlined
              class="selectmin"
              id="company" 
              ref="company" 
              label="경영체✽" 
              v-model="company"
              :items="companyItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
              item-text="name"
              return-object
              @change="changeCompany()"
              @focus="comboCompanies()"
            />
            <v-select dense outlined
              class="selectmin"
              id="farm" 
              ref="farm" 
              label="사업장✽" 
              v-model="farm"
              :items="farmItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
              item-text="name"
              return-object
              @change="changeFarm()"
              @focus="comboFarms()"
            />
          <v-select dense outlined
            class="selectmin"
            id="building" 
            ref="building" 
            label="건물✽" 
            v-model="building"
            :items="buildingItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
            item-text="name"
            return-object
            @change="changeBuilding()"
            @focus="comboBuildings()"
          />
          </div>

          <div class="mt-2 flex">
            <v-text-field dense outlined
              class="textfield"
              id="room_name" 
              ref="room_name" 
              label="위치명✽" 
              v-model="room_name" 
            />
            <v-text-field dense outlined
              style="max-width:150px;"
              class="textfield"
              id="area" 
              ref="area" 
              label="면적"
              placeholder="시작 및 천장위치를 모르실 경우 입력해주세요." 
              suffix="m²"
              onKeyup="this.value=this.value.replace(/[^0-9.]/g,'');"
              maxlength="7"
              v-model="area" 
            />
          </div>

          <div class="mt-5 setting_condition" v-show="building_group === 'BF01'">
            <div class="flex">
              <v-select dense outlined
                style="max-width:150px"
                class="selectmin"
                id="room_type" 
                ref="room_type" 
                :items="room_typeItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                label="돈사구분✽" 
                v-model="room_type" 
                return-object
              />
              <v-text-field dense outlined
                style="max-width:130px;"
                class="textfield"
                id="max_pigcnt" 
                ref="max_pigcnt" 
                label="최대사육두수" 
                suffix="두"
                v-model="max_pigcnt" 
                onKeyup="this.value=this.value.replace(/[^0-9]/g,'');"
                maxlength="3"
              />
            </div>

            <div class="flex mt-2">
              <v-select dense outlined 
                class="selectmin"
                id="feedertype" 
                ref="feedertype" 
                :items="feedertypeItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                label="급이기유형" 
                v-model="feedertype" 
                return-object
                clearable
              />
              <v-select dense outlined 
                class="selectmin" 
                id="bottom" 
                ref="bottom" 
                :items="bottomItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                label="바닥유형" 
                v-model="bottom" 
                return-object
                clearable
              />
            </div>

            <div class="mt-2 flex">
              <v-select dense outlined
                class="selectmin" 
                id="cooltype" 
                ref="cooltype" 
                :items="cooltypeItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                label="냉각방법" 
                v-model="cooltype" 
                return-object
                clearable
              />

              <v-select dense outlined
                class="selectmin"
                id="heattype" 
                ref="heattype" 
                :items="heattypeItems"
                :menu-props="{ top: false, offsetY: true }"
                item-text="name"
                label="보온방법" 
                v-model="heattype" 
                clearable
                return-object
              />
            </div>

            <div class="mt-3 ml-1">
              <h4>공간정보</h4>

              <div class="flex mt-2 vertical-center">
                <div style="width:60px; text-align: center;">시작</div>
                <v-text-field dense outlined
                  class="textfield"
                  id="xposition" 
                  ref="xposition" 
                  label="가로"
                  suffix="m" 
                  v-model="xposition" 
                  onKeyup="this.value=this.value.replace(/[^0-9.]/g,'');"
                  maxlength="4"
                />
                <v-text-field dense outlined
                  class="textfield"
                  id="yposition" 
                  ref="yposition" 
                  label="세로" 
                  suffix="m" 
                  v-model="yposition" 
                  onKeyup="this.value=this.value.replace(/[^0-9]./g,'');"
                  maxlength="4"
                />
              </div>

              <div class="flex mt-2 vertical-center">
                <div style="width:60px; text-align: center;">천장</div>
                <v-text-field dense outlined
                  class="textfield"
                  id="roofheight_h" 
                  ref="roofheight_h" 
                  label="천장 최고높이" 
                  suffix="m" 
                  v-model="roofheight_h" 
                  onKeyup="this.value=this.value.replace(/[^0-9]./g,'');"
                  maxlength="4"
                />
                <v-text-field dense outlined
                  class="textfield"
                  id="roofheight_l" 
                  ref="roofheight_l" 
                  label="천장 최저높이" 
                  suffix="m" 
                  v-model="roofheight_l" 
                  onKeyup="this.value=this.value.replace(/[^0-9]./g,'');"
                  maxlength="4"
                />
              </div>
            </div>
          </div>
          <div class="mt-5">
            <v-menu
              v-model="menuRoomDeldate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined clearable
                  style="max-width:250px;"
                  class="textfield"
                  id="deldate" 
                  ref="deldate" 
                  v-model="deldate"
                  label="삭제일"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title locale="ko-KR"
                v-model="deldate"
                @input="menuRoomDeldate = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="mt-2">
            <v-textarea outlined dense rows="2" class="memo-height"
              label="비고"
              id="history" 
              ref="history" 
              v-model="history"
              value=""
              persistent-hint :hint="hintRoom"
            ></v-textarea>
          </div>

          <div class="flex vertical-center mt-12">
            <v-btn class="rounded-pill normal-btn mr-2 mb-5" 
                :loading= "loadingSave"
                id="save" 
                ref="save" 
                type="submit" 
                @click.prevent="save()"
                v-show= "$getters.userInfo.grade == 'UT01'"
                >
                저장
              </v-btn>
              <v-btn class="rounded-pill new-btn mb-5 mr-2" 
                id="newRegister" 
                ref="newRegister"
                @click="newRegister()">
                신규
              </v-btn>
          </div>
        </div>
        <div class="list">
          <v-text-field dense outlined
            class="textfield"
            v-model="search"
            append-icon="mdi-magnify"
            label=""
            single-line
            hide-details
            clearable
          ></v-text-field>

          <div class="mt-3">
            <v-data-table dense height="650px" class="elevation-3  v-data-table1"
              id="items" 
              ref="items"
              :loading="loadingRoom" 
              :headers="headers"
              :items="items"
              :items-per-page= "500"
              :search="search"
              no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
              fixed-header
              hide-default-footer
              @click:row="infoDetail"
              :sort-by="['농장','축사','room_name']"
              :sort-desc="[false,false,false]"
              group-by="농장"
              show-group-by
              item-key="room_cd"
              single-select
              :item-class="itemRowBackground"
            ></v-data-table>
            <v-card-actions class="justify-center">
              <v-btn rounded-pill class="elevation-5 ma-3 rounded-pill normal-btn" style="width:155px !important;"
                id="refreshList" 
                ref="refreshList" 
                @click="refreshList()">
                <img src="@/assets/icon_refresh.svg" alt="새로고침" />
                목록고침
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";

var save_room_cd = "";

export default {
  name: 'Room',

  data: () => ({
    items : [],
    headers: [
      { text: '구역명', align: 'left', sortable: true, value: 'room_name', width: "170px", groupable: false,},
      { text: '코드', align: 'left', sortable: true, value: 'room_cd', width: "50px", groupable: false,},
      { text: '사업장', sortable: true, value: '농장', width: "160px", },
      { text: '건물', sortable: true, value: '축사', width: "160px", },
      // { text: '돈사구분',sortable: false, value: 'room_type', width: "100px" , groupable: false, class: 'green darken-4 white--text'},
      { text: '등록일', value: 'regdate', width: "120px" , groupable: false, },
      { text: '사용중지일', value: 'deldate', width: "120px" , groupable: false, },
      { text: '비고', value: 'history', width: "250px" , groupable: false, },
    ],

    sensor_items : [],
    sensor_headers: [
      { text: '센서노드 MAC', align: 'left', sortable: true, value: 'sensor_mac', width: "140px", },
      { text: '장치명', value: 'device_name', width: "140px", },
      { text: '비고', align: 'center', sortable: false, value: 'history', width: "80px",},
      { text: '설치일', value: 'setdate', width: "150px", },
      { text: '사용중지일', value: 'deldate', width: "150px",},
      // { text: '비고', value: 'history', width: "500px",},
    ],

    company : {},
    companyItems : [],
    farm : {},
    farmItems : [],
    building : {},
    buildingItems : [],
    search: '',


    room_code : "",
    room_name : "",
    room_type : "",
    room_typeItems : Common.comboBaseCode("RT"),
    max_pigcnt : "",
    bottom : "",
    bottomItems : Common.comboBaseCode("BO"),
    cooltype : "",
    cooltypeItems : Common.comboBaseCode("CT"),
    heattype : "",
    heattypeItems : Common.comboBaseCode("HT"),
    roofheight_h : "",
    roofheight_l : "",

    area : 0,
    building_group : "",

    xposition : "",
    yposition : "",
    feedertype : "",
    feedertypeItems : Common.comboBaseCode("FE"),
    // section_cnt : 0,
    // section_layout : 2,
    // zone_cnt : 0,
    // zone_layout : 3,
    deldate: "",
    history: "",
    datalog : "",
    
    // businessDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    modal: false,
    menuSensorSetdate: false,
    menuSensorDeldate: false,
    menuRoomDeldate: false,

    loadingRoom: false,
    loadingSensor: false,
    loadingSave: false,
    loadingSaveSensor: false,

    hintRoom: "",
    hintSensor: "",

    dialog: false,
    sensorID: "",
    sensor_mac: "",
    sensorDisabled: true,
    // smart_mac: "",
    // sensor_id: "",
    setdate: "",
    delSensordate: "",
    historySensor: "",
    sensorlog : "",

    sensor_cnt: 0,
    msgFlag: true,


  }),
  created() {
    this.comboCompanies();
    this.comboFarms();
    this.comboBuildings();
    this.refreshList();
    sessionStorage.setItem("infoTab",3)
  },

  methods: {
    clearData() {
      // this.company = {};
      // this.farm = {};
      // this.building = {};
      
      this.room_code = "";
      this.room_name = "";
      this.room_type = "";
      this.max_pigcnt = "";
      this.bottom = "";
      this.cooltype = "";
      this.heattype = "";
      this.roofheight_h = "";
      this.roofheight_l = "";
      this.xposition = "";
      this.yposition = "";

      this.area = 0,
      this.building_group = "",

      this.feedertype = "";

      this.deldate= "";
      this.history= "";
      this.datalog = "";

      this.hintRoom = "";
    }, 
    checkData() {
      // console.log("combo data", this.bottom  && this.bottom.code || "empty");
      if (!this.company.code) {
        this.$refs.company.focus();
        return "상위 경영체를 선택입력하세요";
      }
      if (!this.farm.code) {
        this.$refs.farm.focus();
        return "상위 사업장을 선택입력하세요";
      }
      if (!this.building.code) {
        this.$refs.building.focus();
        return "상위 건물을 선택입력하세요";
      }
      if (!this.room_name.trim()) {
        this.$refs.room_name.focus();
        return "위치명을 입력하세요";
      }
      if (this.building_group.code === 'BF01'){
        if (!this.room_type.code) {
          this.$refs.room_type.focus();
          return "돈사구분을 선택하세요";
        }
      }
      // if (!this.max_pigcnt) {
      //   this.$refs.max_pigcnt.focus();
      //   return "최대 사육두수를 입력하세요";
      // }
      return "";
    },
    save() {
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      if (this.room_code) {
        // console.log("업데이트");
        Apis.updateRoom({
          room_cd: this.room_code,
          company_cd: this.company.code,
          farm_cd: this.farm.code,
          building_cd: this.building.code,
          room_name: this.room_name.trim(),
          // 면적 넣기
          area: Number(this.area) || 0,
          room_type: this.room_type && this.room_type.code || "",
          max_pigcnt: Number(this.max_pigcnt) || 0,
          bottom: this.bottom && this.bottom.code || "",
          cooltype: this.cooltype && this.cooltype.code || "",
          heattype: this.heattype && this.heattype.code || "",
          roofheight_h: Number(this.roofheight_h) || 0.0,
          roofheight_l: Number(this.roofheight_l) || 0.0,
          xposition: Number(this.xposition) || 0.0,
          yposition: Number(this.yposition) || 0.0,
          feedertype: this.feedertype && this.feedertype.code || "",
          // section_cnt: Number(this.section_cnt) || 0,
          // section_layout: Number(this.section_layout) || 0,
          // zone_cnt: Number(this.zone_cnt) || 0,
          // zone_layout: Number(this.zone_layout) || 0,
          // etc1: this.etc1,
          // etc2: this.etc2,
          // etc3: this.etc3,
          deldate: this.deldate,
          history: this.history.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.msgFlag = false;
              this.loadingSave = false;
              this.clearData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트",this.company.code);
        Apis.insertRoom({
          company_cd: this.company.code,
          farm_cd: this.farm.code,
          building_cd: this.building.code,
          room_name: this.room_name.trim(),
          room_type: this.room_type && this.room_type.code || "",
          area: Number(this.area) || 0,
          max_pigcnt: Number(this.max_pigcnt) || 0,
          bottom: this.bottom && this.bottom.code || "",
          cooltype: this.cooltype && this.cooltype.code || "",
          heattype: this.heattype && this.heattype.code || "",
          roofheight_h: Number(this.roofheight_h) || 0.0,
          roofheight_l: Number(this.roofheight_l) || 0.0,
          xposition: Number(this.xposition) || 0.0,
          yposition: Number(this.yposition) || 0.0,

          //면적 넣기

          feedertype: this.feedertype && this.feedertype.code || "",
          deldate: this.deldate,
          history: this.history.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.msgFlag = false;
              this.loadingSave = false;
              save_room_cd = res.data;
              this.clearData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      }
    },
    infoDetail(value,row) {
      row.select(true)
      save_room_cd = "";
      this.$store.commit("resMessage","");
      this.loadingRoom = true;
      this.clearData();
      Apis.infoRoom({
        room_cd: value.room_cd, 
        },(res) => {  
          if (res.result) {
            this.sensor_cnt = res.data.sensor_cnt;
            this.room_code = res.data.room_cd;
            // console.log("call data=", res.data);
            this.company = {code: res.data.company_cd.substr(0,11), name: res.data.company_cd.substr(11)};
            this.farm = {code: res.data.farm_cd.substr(0,6), name: res.data.farm_cd.substr(6)};
            // this.building = {code: res.data.building_cd.substr(0,6), name: res.data.building_cd.substr(6)};
            this.building = {code: res.data.building_cd.code, name: res.data.building_cd.name};
            this.room_name = res.data.room_name;
            this.room_type = {code: res.data.room_type.substr(0,4),name: res.data.room_type.substr(4)};
            this.area = res.data.area || 0;
            this.building_group = res.data.building_cd.group;
            this.max_pigcnt = res.data.max_pigcnt || 0;
            this.bottom = {code: res.data.bottom.substr(0,4),name: res.data.bottom.substr(4)};
            this.cooltype = {code: res.data.cooltype.substr(0,4),name: res.data.cooltype.substr(4)};
            this.heattype = {code: res.data.heattype.substr(0,4),name: res.data.heattype.substr(4)};
            this.roofheight_h = res.data.roofheight_h != 0 && res.data.roofheight_h || "";
            this.roofheight_l = res.data.roofheight_l != 0 && res.data.roofheight_l || "";
            this.xposition = res.data.xposition != 0 && res.data.xposition || "";
            this.yposition = res.data.yposition != 0 && res.data.yposition || "";
            this.feedertype = {code: res.data.feedertype.substr(0,4),name: res.data.feedertype.substr(4)};
            this.deldate = res.data.deldate;
            this.history = res.data.history; 
            this.hintRoom = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loadingRoom = false;
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
            this.loadingRoom = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            this.loadingRoom = false;
            // alert(err);
        }
      ); 
    },
    newRegister() {
      this.$store.commit("resMessage","");
      this.company = {};
      this.farm = {};
      this.building = {};
      this.clearData();
      this.$store.commit("resMessage","새로운 위치를 등록할 준비가 되었습니다.");
      this.$refs.company.focus();
    },
    refreshList() {
      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loadingRoom = true;
      // console.log("리스트");
      let paramB = "";
      // console.log("param1",this.$route.params)
      if (this.$route.params.building_cd) {
          paramB = this.$route.params.building_cd;
      }
      Apis.listRooms({
        // company_cd: this.company && this.company.code || "",
        // farm_cd: this.farm && this.farm.code || "",
        // building_cd: this.building && this.building.code || "",
        company_cd: "",
        farm_cd: "",
        building_cd: paramB,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd")
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        this.items = res.data;
        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.msgFlag = true;
        this.loadingRoom = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listFarms 호출 오류",err);
        this.$router.push("/");
        this.loadingRoom = false;
      }) 
    }, 
    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
    },
    comboFarms() {
      Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
        this.building = {};
      }
    },
    comboBuildings() {
      Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              building_group: res.data[i].building_group,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
        this.building_group = this.building.building_group;
      }
    },
    listSensors() {   // 등록 센서 정보;
      this.loadingSensor = true;
      if (!this.room_code) {
        return
      }
      this.clearSensorData();

      Apis.listSensorsInUnit({
        room_cd: this.room_code,
      } ,(res) => {  // 정상처리
        let setcnt = 0;
        res.data.forEach(element => {
          element.sensor_mac = Common.getMacMask(element.sensor_mac);
          // element.smart_mac = Common.getMacMask(element.smart_mac);
          if (!element.deldate)  ++setcnt;
        });
        this.sensor_items = res.data;
        this.sensorlog = "현재 " + res.data.length + "개의 센서가 등록되어 있습니다.";
        if (res.data.length) {
          this.sensorlog = this.sensorlog + "( 가동 :  " + setcnt + ")";
        }
        this.loadingSensor = false;

      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listSensors 호출 오류",err);
        this.$router.push("/");
        this.loadingSensor = false;
      }) 
    },
    detailSensor(value) {   // 등록 센서 정보;
      // console.log("detailsensor",value);
      this.loadingSensor = true;
      this.clearSensorData();
        // console.log("CODE =", code);
      Apis.infoSensorByID({
        id: value.id, 
        },(res) => {  
          if (res.result) {
            this.sensorID = res.data.id;
            this.sensor_mac = Common.getMacMask(res.data.sensor_mac);
            // this.smart_mac = Common.getMacMask(res.data.smart_mac);
            // this.sensor_id = res.data.sensor_id;
            this.setdate = res.data.setdate;
            this.delSensordate = res.data.deldate;
            this.historySensor = res.data.history; 
            this.sensorlog = res.message;
            this.hintSensor = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loadingSensor = false;
          } else {
            this.loadingSensor = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("infoSensor API 호출 오류",err)
            this.loadingSensor = false;
            // alert(err);
        }
      ); 
    },
    saveSensor() {   // 정보 등록 / 수정;
      // console.log("saveSensor");
      let msgCheckdata = this.checkSensorData(); 
      if (msgCheckdata){
        this.sensorlog = msgCheckdata;
        return;
      }
      this.loadingSaveSensor = true;
      if (this.sensorID) {
        // console.log("업데이트");
        Apis.updateSensor({
          "id" : this.sensorID,
          "sensor_mac": this.sensor_mac.replace(/:/gi, ''),
          "smart_mac": "",
          "sensor_id": 0,
          "company_cd": this.company.code,
          "farm_cd" : this.farm.code,
          "building_cd" :	this.building.code,
          "room_cd" :	this.room_code,
          "zone_no" :	1,
          "device_name" :	this.room_name,
          "setdate" :	this.setdate,
          "deldate" :	this.delSensordate || "",
          "history" : this.historySensor.trim(),
          },(res) => {  
            if (res.result) {
              this.loadingSaveSensor = false;
              this.clearSensorData();
              this.listSensors();
              this.sensorlog = res.message;
            } else {
              this.loadingSaveSensor = false;
              console.log("updateSensor API 호출 오류",res.message)
              this.sensorlog = res.message;
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSaveSensor = false;
              console.log("updateSensor API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트",this.company.code);
        Apis.insertSensor({
          "sensor_mac": this.sensor_mac.replace(/:/gi, ''),
          "smart_mac": "",
          "sensor_id": 0,
          "company_cd": this.company.code,
          "farm_cd" : this.farm.code,
          "building_cd" :	this.building.code,
          "room_cd" :	this.room_code,
          "zone_no" :	1,
          "device_name" :	this.room_name,
          "setdate" :	this.setdate,
          "deldate" :	this.delSensordate || "",
          "history" : this.historySensor.trim(),
          },(res) => {  
            if (res.result) {
              this.loadingSaveSensor = false;
              this.clearSensorData();
              this.listSensors();
              this.sensorlog = res.message;
            } else {
              this.loadingSaveSensor = false;
              console.log("insertSensor API 거절",res.message)
              this.sensorlog = res.message;
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSaveSensor = false;
              console.log("insertSensor API 호출 오류",err)
              alert(err);
            }
        )
      }
    },
    newSensorRegister() {   // 새로운 센서 등록 준비 입력항목 clear;
      this.clearSensorData();
      this.sensorDisabled = false;
      this.$refs.sensor_mac.focus();
      this.sensorlog = "새로 입력 준비가 되었습니다, 정보를 센서 맥주소 부터 입력해주세요.";
    },
    closeDialog() {   // 센서 팝업 폼 닫기;
      this.dialog = false;
    },
    clearSensorData() {
      this.sensorDisabled = true;
      this.sensorID = "";
      this.sensor_mac = "";
      // this.smart_mac = "";
      // this.sensor_id = "";
      this.setdate = "";
      this.delSensordate = "";
      this.historySensor = "";
      this.sensorlog = "";
      this.hintSensor = "";
    }, 
    checkSensorData() {
      if (!this.sensor_mac) {
        this.$refs.sensor_mac.focus();
        return "센서 맥 주소를 입력하세요";
      }
      let mac = this.sensor_mac.replace(/:/gi, '')
      if (mac.length == 10) {
        if (isNaN(mac)) {
        this.$refs.sensor_mac.focus();
        return "센서 맥 아이디가 유효하지 않습니다.";
        }
      } else if (mac.length > 14 ||  mac.length < 12) {
        this.$refs.sensor_mac.focus();
        return "센서 맥 아이디가 유효하지 않습니다.";
      }
      if (!this.setdate) {
        this.$refs.setdate.focus();
        return "설치일(가동시작일)을 선택입력하세요";
      }
      return "";
    },
    sensorMacMask(val) {
      // let res = Common.getRegMask(val)
      this.sensor_mac = Common.getMacMask(val)
    },
    // smartMacMask(val) {
    //   // let res = Common.getRegMask(val)
    //   this.smart_mac = Common.getMacMask(val)
    // },
    itemRowBackground(item) {
      return item.room_cd == save_room_cd  ? 'selected' : '' ;
    },


  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
@import './style/info_style.scss';

@media screen and (max-width: 820px){
  .flex_mobile{
    flex-wrap: wrap;
  }
  .dataTable{
    width:100% !important;
  }
  .normal-btn, .new-btn, .dialog-btn{
    width: 70px !important;
  }
}
.dataTable{
  width:60%;
}
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}

.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: #88191a !important;
}

tr.v-data-table__selected {
  background: #9EB0A2 !important;
}
.selected {
  background-color: #9EB0A2;
}
</style>
